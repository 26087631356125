import accordion from '@shared/components/accordion';
import drops from '@shared/components/drops';
import entitlements from '@shared/components/entitlements';
import inViewport from '@shared/components/inViewport';
import itemHistoryModal from '@shared/components/itemHistoryModal';
import lazyLoadBackground from '@shared/components/lazyLoadBackground';
import reveal from '@shared/components/reveal';
import toggleGroupNav from '@shared/components/toggleGroupNav';
import toggleNav from '@shared/components/toggleNav';
import trackClick from '@shared/components/trackClick';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

/**
 * Called once the page is loaded and handles initialising
 * the different components.
 */
const init = () => {
  accordion();
  entitlements();
  drops();
  inViewport();
  itemHistoryModal();
  lazyLoadBackground();
  reveal();
  toggleGroupNav();
  toggleNav();
  trackClick();
};

const canInit = () => {
  const regReady = (window as any).attachEvent ? /d$|^c/ : /d$|^c|^i/;
  return regReady.test(document.readyState || '');
};

let timer: NodeJS.Timeout;

const checkCanInit = () => {
  if (canInit()) {
    if (timer) {
      clearTimeout(timer);
    }

    init();
    return;
  }

  timer = setTimeout(checkCanInit, 100);
};

// update DOM to indicate JavaScript is available
// https://www.paulirish.com/2009/avoiding-the-fouc-v3/
document.documentElement.className = document.documentElement.className.replace(
  /\bno-js\b/,
  'js'
);

checkCanInit();
